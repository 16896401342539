import $ from 'jquery';
import config from '../../../../config';
import {getErrorMessage} from '../../../utils/general';
import {showError, showSuccess} from '../../../layouts/actions';

export const ADMIN_AUTO_QC_SPECS_SHOW_LOADER = 'ADMIN_AUTO_QC_SPECS_SHOW_LOADER';
export const ADMIN_AUTO_QC_SPECS_RECEIVE_LIST = 'ADMIN_AUTO_QC_SPECS_RECEIVE_LIST';

export const setLoading = display => ({type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER, display});

export const getSpecList = () => dispatch => {
    dispatch({
        type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
        display: true
    });

    return $.ajax({
        method: 'GET',
        url: `${config.apiUrl}/v1/qc_on_demand/admin/spec/list`
    })
    .done(res => {
        const {specs, metadata_dictionaries} = res;
        const {conditions, fields, statuses} = metadata_dictionaries || {};
        dispatch({
            type: ADMIN_AUTO_QC_SPECS_RECEIVE_LIST,
            metadataDictionaries: {
                conditions: conditions || [],
                fields: fields || [],
                statuses: statuses || [],
            },
            specs,
            issueCategoryList: res.issue_category_list,
            issueTypeList: res.issue_type_list,
        });
    })
    .fail(error => {
        dispatch(showError(`Could not get the auto-QC specs.  ${getErrorMessage(error)}`));
    })
    .always(() => {
        dispatch({
            type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
            display: false
        });
    });
};

export const saveNewAutoQcSpec = (specName, specItems, callback) => dispatch => {
    dispatch({
        type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
        display: true
    });
    return $.ajax({
        method: 'POST',
        url: `${config.apiUrl}/v1/qc_on_demand/admin/spec/new_spec`,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
            name: specName,
            items: specItems
        })
    })
    .done(() => {
        dispatch(showSuccess(`New spec has been successfully created`));
        callback && callback();
    })
    .fail(error => {
        dispatch(showError(`Could not create new spec.  ${getErrorMessage(error)}`));
    })
    .always(() => {
        dispatch({
            type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
            display: false
        });
    });
};

export const getSpecDetails = (specId) => dispatch => {
    dispatch({
        type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
        display: true
    });

    return $.ajax({
        method: 'GET',
        url: `${config.apiUrl}/v1/qc_on_demand/admin/spec/${specId}/details`
    })
    .fail(error => {
        dispatch(showError(`Could not get the auto-QC specs.  ${getErrorMessage(error)}`));
    })
    .always(() => {
        dispatch({
            type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
            display: false
        });
    });
};

export const updateItemsForAutoQcSpec = (specId, specItems, callback) => dispatch => {
    dispatch({
        type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
        display: true
    });
    return $.ajax({
        method: 'POST',
        url: `${config.apiUrl}/v1/qc_on_demand/admin/spec/${specId}/details`,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
            items: specItems
        })
    })
    .done(() => {
        dispatch(showSuccess(`Spec has been successfully updated`));
        callback && callback();
    })
    .fail(error => {
        dispatch(showError(`Could not update spec.  ${getErrorMessage(error)}`));
    })
    .always(() => {
        dispatch({
            type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
            display: false
        });
    });
};

export const updateNameForAutoQcSpec = (specId, specName, callback) => dispatch => {
    dispatch({
        type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
        display: true
    });
    return $.ajax({
        method: 'POST',
        url: `${config.apiUrl}/v1/qc_on_demand/admin/spec/${specId}/name`,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
            name: specName
        })
    })
        .done(() => {
            dispatch(showSuccess(`Spec has been successfully updated`));
            callback && callback();
        })
        .fail(error => {
            dispatch(showError(`Could not update spec.  ${getErrorMessage(error)}`));
        })
        .always(() => {
            dispatch({
                type: ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
                display: false
            });
        });
};
