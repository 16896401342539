import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage} from '../../utils/general';
import {showError, showSuccess, showInfo} from '../../layouts/actions';
import {getSignedTokenForDownloadFiles} from '../ReportDetails/actions';
import {saveNewRequestState} from '../SeasonDetails/actions';
import {
  NEW_REQUEST_STEP_CS_TAGS,
  QC_REQUEST_TYPE_QUOTE,
  SELECT_FILES_OPTIONS,
  CONTENT_TYPES,
} from '../NewRequest/constants';

export const QUOTE_PROJECT_CLEAR = 'QUOTE_PROJECT_CLEAR';
export const QUOTE_PROJECT_SET_LOADING = 'QUOTE_PROJECT_SET_LOADING';
export const QUOTE_PROJECT_RECEIVE = 'QUOTE_PROJECT_RECEIVE';
export const QUOTE_PROJECT_TOGGLE_EMAIL_PROJECT_ONBOARDING_FORM_MODAL = 'QUOTE_PROJECT_TOGGLE_EMAIL_PROJECT_ONBOARDING_FORM_MODAL';

export const clearQuoteProject = () => ({type: QUOTE_PROJECT_CLEAR});

export const getQuoteProject = (history, quoteProjectId) => (dispatch, getState) => {
  const loadingName = 'loadingQuote';
  dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: true});
  const {quoteData: currentData} = getState().quoteProject;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project/${quoteProjectId}`
  })
  .done(res => {
    const {
      quote_project: quoteData,
      estimated_quote: estimatedQuote,
      quote_project_ext,
      client_info: clientInfo,
      filters: dictionaries
    } = res;
    dispatch({
      type: QUOTE_PROJECT_RECEIVE,
      quoteData: {
        ...quoteData,
        extended_info: quote_project_ext
      },
      estimatedQuote,
      clientInfo,
      dictionaries
    });
  })
  .fail(error => {
    dispatch(showError(`Could not ${currentData ? 're' : ''}load quote project.  ${getErrorMessage(error)}`));
    if (history) {
      history.push('/requests');
    } else {
      window.location.href = '/requests';
    }
  })
  .always(() => {
    dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const showEmailProjectOnboardingFormModal = () => ({type: QUOTE_PROJECT_TOGGLE_EMAIL_PROJECT_ONBOARDING_FORM_MODAL, isShow: true});

export const closeEmailProjectOnboardingFormModal = () => ({type: QUOTE_PROJECT_TOGGLE_EMAIL_PROJECT_ONBOARDING_FORM_MODAL, isShow: false});

export const sendEmailProjectOnboardingForm = values => (dispatch, getState) => {
  const loadingName = 'sendingEmail';
  dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: true});
  const {subjectLine, distroList, paymentTerms} = values;
  const {quoteData, estimatedQuote} = getState().quoteProject;
  const quoteProjectId = quoteData.extended_info.QuoteProjectID;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project/${quoteProjectId}/client_onboarding_form/send_email`,
    data: {
      subject_line: subjectLine,
      distribution_list: distroList,
      payment_terms: paymentTerms
    }
  })
  .done(() => {
    dispatch(closeEmailProjectOnboardingFormModal());
    dispatch({
      type: QUOTE_PROJECT_RECEIVE,
      quoteData: {
        ...quoteData,
        extended_info: {
          ...quoteData.extended_info,
          EmailClientOnboardingFormSentAt: Date.now()
        }
      },
      estimatedQuote: {
        ...estimatedQuote,
        paymentTerms
      }
    });
    dispatch(showSuccess('Email has been successfully sent'));
  })
  .fail(error => {
    const errMsg = getErrorMessage(error) || '';
    if (errMsg.indexOf('STATUS: ') !== -1) {
      dispatch(showInfo(errMsg.replace('STATUS: ', '').replace('(Invalid request parameters)', '')));
      dispatch(getQuoteProject(null, quoteProjectId));
      dispatch(closeEmailProjectOnboardingFormModal());
    } else {
      dispatch(showError(errMsg.indexOf('Could not send email') === -1 ? `Could not send email.  ${errMsg}` : errMsg));
    }
  })
  .always(() => {
    dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const sendEmailNewProjectQuote = () => (dispatch, getState) => {
  const loadingName = 'sendingEmail';
  dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: true});
  const {quoteData} = getState().quoteProject;
  const quoteProjectId = quoteData.extended_info.QuoteProjectID;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project/${quoteProjectId}/new_project/send_email`
  })
  .done(() => {
    dispatch({
      type: QUOTE_PROJECT_RECEIVE,
      quoteData: {
        ...quoteData,
        extended_info: {
          ...quoteData.extended_info,
          RequestStatusID: config.requestStatuses.PENDING,
          QcStatusID: config.qcStatus.REVIEW,
          StatusesChangedAt: Date.now(),
          EmailClientNewProjectSentAt: Date.now()
        }
      }
    });
    dispatch(showSuccess('Email has been successfully sent'));
  })
  .fail(error => {
    const errMsg = getErrorMessage(error) || '';
    if (errMsg.indexOf('STATUS: ') !== -1) {
      dispatch(showInfo(errMsg.replace('STATUS: ', '').replace('(Invalid request parameters)', '')));
      dispatch(getQuoteProject(null, quoteProjectId));
    } else {
      dispatch(showError(errMsg.indexOf('Could not send email') === -1 ? `Could not send email.  ${errMsg}` : errMsg));
    }
  })
  .always(() => {
    dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const createQcRequests = withWOs => (dispatch, getState) => {
  const loadingName = 'creatingQcRequests';
  dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: true});
  const {quoteData, clientInfo} = getState().quoteProject;
  const quoteProjectId = quoteData.extended_info.QuoteProjectID;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project/${quoteProjectId}/create_requests`,
    data: {
      with_wo: withWOs
    }
  })
  .done(res => {
    dispatch({
      type: QUOTE_PROJECT_RECEIVE,
      clientInfo: {
        ...clientInfo,
        created_requests: (res.created_requests || [])
      }
    });
    dispatch(showSuccess('QC requests has been successfully created'));
  })
  .fail(error => {
    dispatch(showError(`Could not create QC requests.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const cancelProject = () => (dispatch, getState) => {
  const loadingName = 'cancelling';
  dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: true});
  const {quoteData} = getState().quoteProject;
  const quoteProjectId = quoteData.extended_info.QuoteProjectID;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project/${quoteProjectId}/cancel`
  })
  .done(() => {
    dispatch({
      type: QUOTE_PROJECT_RECEIVE,
      quoteData: {
        ...quoteData,
        extended_info: {
          ...quoteData.extended_info,
          RequestStatusID: config.requestStatuses.CANCELLED
        }
      }
    });
    dispatch(showSuccess('Project Quote was canceled'));
  })
  .fail(error => {
    dispatch(showError(`Could not cancel project.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
  });
};

export const downloadAsPdf = () => (dispatch, getState) => {
  const {quoteData} = getState().quoteProject;
  const quoteProjectId = quoteData.extended_info.QuoteProjectID;
  const path = `/v1/qc_on_demand/requests/quote_project/${quoteProjectId}/download`;
  getSignedTokenForDownloadFiles(path, 'type=pdf');
};

export const editQuoteProject = (history, isNewRevision) => (dispatch, getState) => {
  const loadingName = 'recreating';
  dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: true});
  const {quoteData} = getState().quoteProject;
  const {
    extended_info: {
      QuoteProjectID,
      RevisionNum
    },
    title,
    studio_id,
    production_id,
    cs_studio_id,
    cs_production_id,
    series_id,
    series_name,
    trailer_episode_name,
    show_code,
    distribution_list,
    client_contact_id,
    post_executive_user_id,
    cs_user_id,
    office_flag,
    pending_approval_studio_partner_type_ids,
    send_from_qod,
    is_create_client_proxies,
    //upload_later,
    media_delivery,
    content_type_id,
    distributor_id,
    general,
    qc_profiles_new,
    include_studio_logo,
    estimated_quote
  } = quoteData;
  if (qc_profiles_new) {
    qc_profiles_new.forEach(p => {
      if (p.season) {
        p.season.total_episodes = (p.season.episodes ? p.season.episodes.length : 0);
        if (p.season.episodes) {
          p.season.episodes.forEach(ep => ep.created = Date.now());
        }
      }
    });
  }
  const isSeason = content_type_id === CONTENT_TYPES.Season;
  const data = {
    date: new Date(),
    currentStep: NEW_REQUEST_STEP_CS_TAGS,
    currentSubStep: null,
    values: {
      request_type_id: QC_REQUEST_TYPE_QUOTE,
      title,
      studio_id: studio_id || undefined,
      production_id: production_id || undefined,
      cs_studio_id: cs_studio_id || undefined,
      cs_production_id: cs_production_id || undefined,
      series_id: series_id || series_name,
      trailer_episode_name,
      season_name: (((qc_profiles_new || [])[0] || {}).season || {}).season_number,
      show_code,
      distribution_list,
      client_contact_id,
      post_executive_user_id,
      cs_user_id,
      office_flag,
      pending_approval_studio_partner_type_ids,
      send_from_qod,
      is_create_client_proxies,
      select_files_type_id: isSeason ? undefined : media_delivery ? SELECT_FILES_OPTIONS.PhysicalMediaDelivery : SELECT_FILES_OPTIONS.UploadLater,
      content_type_id,
      distributor_id,
      general,
      include_studio_logo,
      qc_profile_id: qc_profiles_new,
      quote_project_id: QuoteProjectID,
      estimated_quote: estimated_quote,
      quote_revision_num: RevisionNum,
      create_new_revision: isNewRevision
    }
  };
  dispatch(saveNewRequestState(data))
    .then(
      () => {
        dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
        const urlParam = cs_studio_id ? `cs_studio=${cs_studio_id}` : `cs_production=${cs_production_id}`;
        const redirectUrl = `/requests/new?${urlParam}&fromDraft=true&qp=${QuoteProjectID}`;
        history.push(redirectUrl);
      },
      err => {
        dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
        dispatch(showError(`Failed save this quote project.  ${getErrorMessage(err)}`));
      }
    );
};

export const saveProjectItemizedList = (itemizedList, sendEmail) => (dispatch, getState) => {
  const loadingName = 'cancelling';
  dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: true});
  const {quoteData} = getState().quoteProject;
  const quoteProjectId = quoteData.extended_info.QuoteProjectID;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project/${quoteProjectId}/itemized_list`,
    data: JSON.stringify(itemizedList)
  })
      .done(() => {
        if (sendEmail) {
          dispatch(sendEmailNewProjectQuote());
        } else {
          dispatch(downloadAsPdf());
        }
        dispatch({
          type: QUOTE_PROJECT_RECEIVE,
          estimatedQuote: {
            ...quoteData.estimated_quote,
            itemizedList: itemizedList
          }
        });
      })
      .fail(error => {
        dispatch(showError(`Failed save steps for PDF.  ${getErrorMessage(error)}`));
      })
      .always(() => {
        dispatch({type: QUOTE_PROJECT_SET_LOADING, name: loadingName, loading: false});
      });
};
