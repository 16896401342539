import React, {useState} from 'react';
import styled from 'styled-components';
import {Collapse} from 'react-bootstrap';
import Formats from './Formats';
import { v1 as uuidv1 } from 'uuid';

function FormatsForQuote(props) {
  const {
    data,
    filters,
    onChange
  } = props;

  const qcProfilesList = data.qc_profile_id || [];

  const [collapsed, setCollapsed] = useState({});

  const handleChange = (profileIdx, name, value) => {
    if (name === 'video_format' || name === 'captions') {
      onChange(name, null);
    } else if (name !== 'audio_format') {
      return;
    }

    // for link with steps from itemized items
    if (name === 'video_format' || name === 'captions') {
      value.map((videoFormatItem, itemIndex) => {
        videoFormatItem.profile_index = '' + profileIdx + itemIndex;
        videoFormatItem.profile_uuid = videoFormatItem.profile_uuid || uuidv1();
        return videoFormatItem;
      });
    }

    const newQcProfiles = [...qcProfilesList];
    newQcProfiles[profileIdx] = {
      ...newQcProfiles[profileIdx],
      [name]: value
    };
    if (name === 'audio_format') {
      newQcProfiles[profileIdx].profile_uuid = uuidv1();
      delete newQcProfiles[profileIdx].additional_description;
      delete newQcProfiles[profileIdx].version_tag;
    }
    onChange('qc_profile_id', newQcProfiles);
  };

  return (
    <StyledContainer>
      {qcProfilesList.map(profileItem => ({
        profile: filters.qc_profiles.find(p => p.value === profileItem.qc_profile_id),
        profileData: profileItem
      })).map((profileItem, profileIdx) => {
        const {profile, profileData} = profileItem;
        const qcType = filters.qc_types.find(t => t.value === profileData.qc_type_id) || {};
        if (!qcType.is_audio && !qcType.is_video && !qcType.is_captions) {
          return null;
        }
        const isExpanded = !collapsed[profileIdx];
        return !!profile && (
          <div
            key={`format-${profileIdx}`}
            className="combo-item-cont"
          >
            <div
              className="combo-item-title"
              onClick={() => setCollapsed({...collapsed, [profileIdx]: isExpanded})}
            >
              <strong title="QC Profile">
                {profile.name}
              </strong>
              <span>
                <i className="fa-light fa-angle-right"/>
              </span>
              <strong title="QC Type">
                {qcType.name}
              </strong>
              <span className={isExpanded ? undefined : 'collapsed'}>
                <i className="fa-light fa-angle-right"/>
              </span>
            </div>
            <Collapse in={isExpanded}>
              <div>
                <Formats
                  isNewQuote
                  data={profileData}
                  filters={filters}
                  onSelect={(n, v) => handleChange(profileIdx, n, v)}
                />
              </div>
            </Collapse>
          </div>
        );
      })}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin-bottom: 30px;
  .combo-item-cont {
    + .combo-item-cont {
      margin-top: 15px;
    }
    .combo-item-title {
      margin: 32px 20px 10px 73px;
      width: fit-content;
      border-radius: 2px;
      background: #f3f3f3;
      box-shadow: -3px 0 0 3px #f3f3f3;
      cursor: pointer;
      span {
        margin: 0 8px;
        font-weight: 300;
        i {
          transition: transform 0.2s ease 0s;
          will-change: transform;
        }
        &.collapsed {
          i {
            transform: rotate(90deg);
          }
        }
      }
    }
    .step-body-wrapper {
      margin-left: 90px !important;
    }
  }
`;

export default FormatsForQuote;
