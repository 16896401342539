/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../Login/actions';
import {
    ADMIN_AUTO_QC_SPECS_SHOW_LOADER,
    ADMIN_AUTO_QC_SPECS_RECEIVE_LIST,
} from './actions';

const initialState = {
    showLoader: false,
    specs: [],
    issueCategoryList: [],
    issueTypeList: [],
    metadataDictionaries: {
        conditions: [],
        fields: [],
        statuses: [],
    },
    specModalData: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_SET_IS_LOGOUT: {
            return initialState;
        }

        case ADMIN_AUTO_QC_SPECS_SHOW_LOADER: {
            return {
                ...state,
                showLoader: action.display
            };
        }

        case ADMIN_AUTO_QC_SPECS_RECEIVE_LIST: {
            return {
                ...state,
                metadataDictionaries: action.metadataDictionaries || state.metadataDictionaries,
                specs: action.specs || state.specs,
                issueCategoryList: action.issueCategoryList || state.issueCategoryList,
                issueTypeList: action.issueTypeList || state.issueTypeList,
            };
        }

        default:
            return state;
    }
}
